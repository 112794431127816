<template>
  <reference-organization />
</template>

<script>
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "Organization.vue",
  components: {
    ReferenceOrganization: () =>
      import("@/components/reference/ReferenceOrganization.vue"),
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
});
</script>